'use client';

import React from 'react';
import { EdgeProps, getBezierPath } from 'reactflow';
export default function TurboEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  markerStart
}: EdgeProps) {
  const xEqual = sourceX === targetX;
  const yEqual = sourceY === targetY;
  const [edgePath] = getBezierPath({
    // we need this little hack in order to display the gradient for a straight line
    sourceX: xEqual ? sourceX + 0.0001 : sourceX,
    sourceY: yEqual ? sourceY + 0.0001 : sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition
  });
  return <>
      <path markerStart={markerStart} id={id} style={style} className="react-flow__edge-path" d={edgePath}
    //markerEnd={markerEnd}
    data-sentry-element="path" data-sentry-source-file="TurboEdge.tsx" />
    </>;
}
export const edgeTypes = {
  turbo: TurboEdge
};