import { PATH_USERS, pathJoin } from '#/services/s3/filepaths';
import { readJsonFile } from '#/services/s3/actions/json/readJsonFile';
import { IWorkflowRun } from '#/types/workspace';

import getWorkflowRunOutput from './getWorkflowRunOutput';

export async function getWorkflowRun({
  ownerAddress,
  workflowUuid,
  runId,
}: {
  ownerAddress: string;
  workflowUuid: string;
  runId: string;
}): Promise<IWorkflowRun | null> {
  const s3ObjectKey = pathJoin(PATH_USERS, String(ownerAddress), 'workflows', String(workflowUuid), 'runs', `${String(runId)}.json`);
  const json = await readJsonFile<IWorkflowRun>({ s3ObjectKey });
  if (json == null) {
    return null;
  }
  const output = await getWorkflowRunOutput({
    ownerAddress,
    workflowUuid,
    runId,
  });
  return {
    ...json,
    output: output ?? {},
  };
}

export default getWorkflowRun;
