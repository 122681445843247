import { debug } from '#/utils/debug';

export const PATH_COMMUNITY_TEMPLATES = 'communitytemplates/';
export const PATH_SERVICES = 'openapiservices/';
export const PATH_USERS = 'users/';

export function pathJoin(...args: string[]): string {
  const path: string[] = [];

  const optionalTrailingSlash = args[args.length - 1]?.endsWith('/') ? '/' : '';

  for (const arg of args) {
    // Trim leading and trailing slashes from each segment, so that the arguments end up joined with only one `/` separator
    const trimmedArg = arg.replace(/^\/+|\/+$/g, '');

    //
    // TODO: Do we want empty arguments to just vanish, or do we want to raise an error?
    //
    // (Or we could make this configurable)
    //
    if (trimmedArg === '') {
      debug(pathJoin, 'An empty argument was passed:', args);
      continue;
    }

    path.push(trimmedArg);
  }

  return path.join('/') + optionalTrailingSlash;
}

export function isJsonPath(path: string | any) {
  return typeof path === 'string' && path.endsWith('.json');
}

export function isPlaceholder(fileKey: string): boolean {
  return fileKey.includes('.file_placeholder'); // <-- TODO: Should this be fileKey.endsWith()?
}
