import { AWS_S3_BUCKET } from '#/config/constants';
import { s3 } from '#/services/s3';
import { IProfile, Profile } from '#/types/profile';
import { parseJson } from '#/utils/json';
import { PATH_USERS, pathJoin } from '../filepaths';

export default async function getProfile(address: string): Promise<any> {
  const userHome = pathJoin(PATH_USERS, address);
  const profileKey = pathJoin(userHome, 'profile.json');

  const getObjectParams = {
    Bucket: AWS_S3_BUCKET,
    Key: profileKey,
  };

  try {
    const profileData = await s3.getObject(getObjectParams).promise();
    if (profileData.Body) {
      const profile: IProfile = parseJson<IProfile>(
        typeof profileData?.Body !== 'string'
          ? profileData?.Body.toString()
          : profileData?.Body,
      );
      console.debug(`[getProfile] Profile:`, profile);
      return profile;
    }
  } catch (error: any) {
    console.error(`Error getting profile file: ${error}`);
    throw new Error(`Error getting profile: ${error.message}`);
  }
}
