'use client';

import React from 'react';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import NetworkSwitcher from './NetworkSwitcher';
// import {
//   //HamburgerMenuIcon,
//   DotFilledIcon,
//   CheckIcon,
//   ChevronRightIcon,
// } from '@radix-ui/react-icons';

interface AddressBarInterface {
  isInWorkspace?: boolean;
}
export function AddressBar({
  isInWorkspace
}: AddressBarInterface) {
  const pathname = usePathname();
  const router = useRouter();
  const renderSegments = () => {
    if (!pathname) return null;
    const segments = pathname.split('/').slice(1);
    return segments.map((segment, index) => {
      const decodedSegment = decodeURIComponent(segment);
      const href = '/' + segments.slice(0, index + 1).join('/');
      return <React.Fragment key={segment}>
          <Link href={href}>
            <span className="animate-[highlight_1s_ease-in-out_1] rounded-full px-1.5 py-0.5 text-gray-100 text-xs cursor-pointer">
              {decodedSegment}
            </span>
          </Link>
          <span className="text-gray-600">/</span>
        </React.Fragment>;
    });
  };
  return <div className="p-2 flex flex-row justify-between" data-sentry-component="AddressBar" data-sentry-source-file="AddressBar.tsx">
      <div className="flex col-span-4 items-center gap-x-2 p-3.5 lg:px-5 lg:py-3">
        <div className="text-gray-600">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4" viewBox="0 0 20 20" fill="currentColor" data-sentry-element="svg" data-sentry-source-file="AddressBar.tsx">
            <path fillRule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clipRule="evenodd" data-sentry-element="path" data-sentry-source-file="AddressBar.tsx" />
          </svg>
        </div>
        <div className="flex gap-x-1 text-sm font-medium">
          <DropdownMenu.Root data-sentry-element="unknown" data-sentry-source-file="AddressBar.tsx">
            <DropdownMenu.Trigger asChild data-sentry-element="unknown" data-sentry-source-file="AddressBar.tsx">
              <div>
                <Link href="/dashboard/workspaces" prefetch={true} data-sentry-element="Link" data-sentry-source-file="AddressBar.tsx">
                  <span className="text-teal-100 hover:cursor-pointer no-underline px-2">
                    SimpFi<span className="text-green-500">.Ai</span>
                  </span>
                </Link>
              </div>
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal data-sentry-element="unknown" data-sentry-source-file="AddressBar.tsx">
              {/* Dropdown menu content */}
            </DropdownMenu.Portal>
          </DropdownMenu.Root>
          {renderSegments()}
        </div>
      </div>
      <div className="flex flex-row gap-x-2">
        <NetworkSwitcher data-sentry-element="NetworkSwitcher" data-sentry-source-file="AddressBar.tsx" />
        <ConnectButton label="Connect" chainStatus="none" accountStatus="avatar" data-sentry-element="ConnectButton" data-sentry-source-file="AddressBar.tsx" />
      </div>
    </div>;
}