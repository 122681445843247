import { PATH_USERS, pathJoin } from '#/services/s3/filepaths';
import { readJsonFile } from '#/services/s3/actions/json/readJsonFile';

export async function getWorkflowRunOutput({
  ownerAddress,
  workflowUuid,
  runId,
}: {
  ownerAddress: string;
  workflowUuid: string;
  runId: string;
}): Promise<Record<string, any> | null> {
  const outputKey = pathJoin(PATH_USERS, String(ownerAddress), 'workflows', String(workflowUuid), 'runs', String(runId), 'output.json');
  const json = await readJsonFile<Record<string, any>>({ s3ObjectKey: outputKey });
  return json;
}

export default getWorkflowRunOutput;
