'use client';

import { MagicAuthConnector } from '@everipedia/wagmi-magic-connector';
import { Wallet as RainbowWallet } from '@rainbow-me/rainbowkit';
import Cookies from 'js-cookie';
import { Chain } from "wagmi";

// Define network configurations
const networkConfig: { [key: string]: { chainId: number; rpcUrl: string } } = {
  defaultNetwork: {
    chainId: 8453,
    rpcUrl: 'https://mainnet.base.org',
  },
  // Add other network configurations here
  ethereum: {
    chainId: 1,
    rpcUrl: 'https://rpc.ankr.com/eth',
  },
  base: {
    chainId: 8453,
    rpcUrl: 'https://mainnet.base.org',
  },
  sepolia: {
    chainId: 11155111,
    rpcUrl: 'https://rpc.ankr.com/eth_sepolia',
  },
  baseSepolia: {
    chainId: 84532,
    rpcUrl: 'https://sepolia.base.org',
  },
};

export const rainbowMagicConnector = (chains: Chain[]): RainbowWallet => {
  // Get network information from a cookie
  const network = Cookies.get('network') || 'base'; // Replace 'defaultNetwork' with your default network
  const selectedNetwork = networkConfig[network] || networkConfig.defaultNetwork;
  return {
    id: 'magic',
    name: 'Use Email Login',
    iconUrl: '/simpfinogrid.png',
    iconBackground: '#000',
    createConnector: () => ({
      connector: new MagicAuthConnector({
        chains,
        options: {
          apiKey: process.env.NEXT_PUBLIC_MAGIC_LINK_PK ?? '',
          accentColor: '#30E000',
          customLogo: '/simpfinogrid.png',
          isDarkMode: true,
          magicSdkConfiguration: {
            network: {
              chainId: selectedNetwork.chainId,
              rpcUrl: selectedNetwork.rpcUrl,
            },
          },
        },
      }),
    }),
  };
};
