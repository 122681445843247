import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'next/navigation';
import { Edge, Node, useEdgesState, useNodesState, useReactFlow } from 'reactflow';
import { flatten, omit, set } from 'lodash';
import { API_ROOT, URL_ROOT } from '#/config/env';
import { INode, IWorkspace, NodeData } from '#/types/workspace';
import { parseJson } from '#/utils/json';
import { useUserContext } from './UserContext';
import getLatestWorkflowRun from '../services/workflows/runs/getLatestWorkflowRun';
import { getAuthFile } from '#/services/s3/actions/auth/getAuthFile';
import { CredentialType } from '#/services/s3/actions/auth/getAuthFilePath';
import getUserAuthFile from '../services/s3/actions/auth/getUserAuthFile';
import newHistory, { History } from '../utils/history';
async function isAuthedWithServiceKey(serviceKey: string) {
  try {
    const {
      user
    } = await (await fetch(`${API_ROOT}/me`)).json();
    const userAddress = user.address;

    // Fetch the auth file for the service
    const authFile = await getUserAuthFile({
      address: userAddress,
      service: serviceKey,
      credentialType: CredentialType.OAuth2AccessToken
    });
    console.debug('[isAuthedWithServiceKey] authFile:', authFile);

    // Check if the auth file exists and is valid
    return authFile != null;
  } catch (error: any) {
    console.error(error);
    return false;
  }
}
async function isMetPrerequisite(node: INode, prerequisite: string) {
  return prerequisite === 'OAUTH_1_0A' ? (node?.data?.serviceKey ?? '') !== '' && (await isAuthedWithServiceKey(node?.data?.serviceKey ?? '')) : true;
}
async function runPreFlightCheck(workspace: IWorkspace) {
  const nodePrerequisites = await Promise.all(flatten(workspace.nodes.map(node => (node?.data?.prerequisites ?? []).map(async prerequisite => ({
    nodeLabel: `${node?.data?.title} ${node?.data?.subline}`,
    prerequisite,
    isMet: await isMetPrerequisite(node, prerequisite),
    message: prerequisite === 'OAUTH_1_0A' ? 'Authentication required.' : prerequisite,
    messageDetails: `Login from \`${URL_ROOT}/dashboard/account/connected-accounts\` to continue.`
  })))));
  const unmetPrerequisites = nodePrerequisites.filter(p => !p.isMet);
  return {
    isReadyToRun: unmetPrerequisites.length === 0,
    unmetPrerequisites
  };
}
type UpdateFunction<T> = (value: T) => void;
interface FlowContextData {
  isLoading: boolean;
  isTestRunning: boolean;
  setIsTestRunning: UpdateFunction<boolean>;
  workspace?: IWorkspace;
  fetchWorkspace: () => Promise<void>;
  canDeleteNode?: boolean;
  setCanDeleteNode: UpdateFunction<boolean>;
  isWorkspaceInputsShown?: boolean;
  setIsWorkspaceInputsShown: UpdateFunction<boolean>;
  toggleIsWorkspaceInputsShown: () => void;
  onChangeWorkspaceInputs: (value: string) => void;
  workspaceInputsError: string;
  workspaceInputsStr: string;
  workspaceInputsJson: Record<string, any>;
  isWorkspaceInfoShown?: boolean;
  setIsWorkspaceInfoShown: UpdateFunction<boolean>;
  toggleIsWorkspaceInfoShown: () => void;
  isSettingsShown?: boolean;
  setIsSettingsShown: UpdateFunction<boolean>;
  toggleIsSettingsShown: () => void;
  isOfferInfoShown?: boolean;
  setIsOfferInfoShown: UpdateFunction<boolean>;
  toggleIsOfferInfoShown: () => void;
  selectedNodeProps?: any;
  setSelectedNodeProps: UpdateFunction<any>;
  selectedNodeData?: any;
  setSelectedNodeData: UpdateFunction<any>;
  isNodeDetailsShown?: boolean;
  setIsNodeDetailsShown: UpdateFunction<boolean>;
  toggleIsNodeDetailsShown: () => void;
  unmetPrerequisites: any[];
  handleRunPreFlightCheck: () => Promise<{
    isReadyToRun: boolean;
    unmetPrerequisites: any[];
  }>;
  isAuthModalShown?: boolean;
  setIsAuthModalShown: UpdateFunction<boolean>;
  toggleIsAuthModalShown: () => void;
  nodes: any[];
  setNodes: Dispatch<SetStateAction<Node<NodeData, string | undefined>[]>>;
  onNodesChange: (nodes: any[]) => void;
  edges: any[];
  setEdges: UpdateFunction<any[]>;
  onEdgesChange: (edges: any[]) => void;
  workflowRunData: any;
  isWorkflowRunDataLoading: boolean;
  fetchWorkflowRunData: () => Promise<void>;
  undo: () => void;
  redo: () => void;
  saveState: () => void;
}

// Create a new context
export const FlowContext = createContext({
  isLoading: true,
  isTestRunning: false,
  setIsTestRunning: (value: boolean) => {},
  // workspace: null,
  fetchWorkspace: async () => {},
  canDeleteNode: false,
  setCanDeleteNode: (value: boolean) => {},
  isWorkspaceInputsShown: false,
  setIsWorkspaceInputsShown: (value: boolean) => {},
  toggleIsWorkspaceInputsShown: () => {},
  onChangeWorkspaceInputs: (value: string) => {},
  workspaceInputsError: '',
  workspaceInputsStr: '',
  workspaceInputsJson: {},
  isWorkspaceInfoShown: false,
  setIsWorkspaceInfoShown: (value: boolean) => {},
  toggleIsWorkspaceInfoShown: () => {},
  isSettingsShown: false,
  setIsSettingsShown: (value: boolean) => {},
  toggleIsSettingsShown: () => {},
  selectedNodeProps: undefined,
  setSelectedNodeProps: (value: any) => {},
  selectedNodeData: undefined,
  setSelectedNodeData: (value: any) => {},
  isNodeDetailsShown: false,
  setIsNodeDetailsShown: (value: boolean) => {},
  toggleIsNodeDetailsShown: () => {},
  isOfferInfoShown: false,
  setIsOfferInfoShown: (value: boolean) => {},
  toggleIsOfferInfoShown: () => {},
  unmetPrerequisites: [],
  handleRunPreFlightCheck: async () => ({
    isReadyToRun: false,
    unmetPrerequisites: []
  }),
  isAuthModalShown: false,
  setIsAuthModalShown: (value: boolean) => {},
  toggleIsAuthModalShown: () => {},
  nodes: [],
  setNodes: (value: Node[]) => {},
  onNodesChange: (nodes: Node[]) => {},
  edges: [],
  setEdges: (value: Edge[]) => {},
  onEdgesChange: (edges: Edge[]) => {},
  undo: () => {},
  redo: () => {},
  saveState: () => {},
  workflowRunData: {
    meta: 'Loading...'
  },
  isWorkflowRunDataLoading: true,
  fetchWorkflowRunData: async () => {}
} as FlowContextData);

// Custom hook to use the context
export const useFlowContext = () => {
  return useContext(FlowContext);
};

// Provider component
export const FlowProvider = ({
  initialWorkspace,
  children
}: {
  children: any;
}) => {
  const {
    workspace: workspaceUuid
  } = useParams<{
    workspace: string;
  }>() ?? {};
  const [isLoading, setIsLoading] = useState(true);
  const [isTestRunning, setIsTestRunning] = useState(false);
  const {
    userSession,
    user
  } = useUserContext();
  const userAddress = userSession?.address;
  const [workspace, setWorkspace] = useState<IWorkspace>();
  const [nodes, setNodes, onNodesChange] = useNodesState((workspace?.nodes ?? []) as Node[]);
  const [edges, setEdges, onEdgesChange] = useEdgesState((workspace?.edges ?? []) as Edge[]);
  const [history, setHistory] = useState<History<{
    nodes: Node[];
    edges: Edge[];
  }>>(newHistory());
  const undo = () => {
    const {
      success,
      history: changedHistory
    } = history.undo();
    if (success) {
      const {
        nodes,
        edges
      } = changedHistory.current() ?? {
        nodes: [],
        edges: []
      };
      setHistory(changedHistory);
      setNodes(nodes);
      setEdges(edges);
    }
  };
  const redo = () => {
    const {
      success,
      history: changedHistory
    } = history.redo();
    if (success) {
      const {
        nodes,
        edges
      } = changedHistory.current() ?? {
        nodes: [],
        edges: []
      };
      setHistory(changedHistory);
      setNodes(nodes);
      setEdges(edges);
    }
  };
  const saveState = () => {
    const currentState = history.current();
    if (!currentState || JSON.stringify(currentState) !== JSON.stringify({
      nodes,
      edges
    })) {
      setHistory(history.write({
        nodes,
        edges
      }));
    }
  };
  const [isWorkspaceInputsShown, setIsWorkspaceInputsShown] = useState(false);
  function toggleIsWorkspaceInputsShown() {
    setIsWorkspaceInputsShown(!isWorkspaceInputsShown);
  }
  const [workspaceInputsError, setWorkspaceInputsError] = useState<string>('');
  const [workspaceInputsStr, setWorkspaceInputsStr] = useState<string>('');
  const [workspaceInputsJson, setWorkspaceInputsJson] = useState<Record<string, any>>(workspace?.input);
  const onChangeWorkspaceInputs = (value: string) => {
    setWorkspaceInputsStr(value);
    try {
      const json = JSON.parse(value);
      setWorkspaceInputsJson(json);
      setWorkspaceInputsError('');
    } catch (error) {
      setWorkspaceInputsError(`Invalid JSON: ${(error as any).message}`);
      // toast.error('Invalid JSON');
    }
  };
  async function fetchWorkspace() {
    console.debug('[fetchWorkspace]', {
      userAddress,
      user
    });
    if (userAddress == null || user == null) {
      return;
    }
    try {
      setIsLoading(true);
      // TODO: check workspace integrity and throw an error inside the system
      // read function
      const currentWorkspace: IWorkspace = await user.getWorkspace(workspaceUuid);
      console.debug('[fetchWorkspace] currentWorkspace:', currentWorkspace);
      setWorkspace(currentWorkspace);
      setNodes(currentWorkspace?.nodes ?? []);
      setEdges(currentWorkspace?.edges ?? []);
      setWorkspaceInputsJson(currentWorkspace?.input);
      setWorkspaceInputsStr(JSON.stringify(currentWorkspace?.input, null, 2));
    } catch (error) {
      console.error(error);
      // Handle the error as appropriate
    } finally {
      setIsLoading(false);
    }
  }
  useEffect(() => {
    fetchWorkspace();
  }, [userAddress, user, workspaceUuid]);

  // const { getNodes, setNodes } = useReactFlow();

  const [canDeleteNode, setCanDeleteNode] = useState<boolean>(true);
  const [isWorkspaceInfoShown, setIsWorkspaceInfoShown] = useState(false);
  function toggleIsWorkspaceInfoShown() {
    setIsWorkspaceInfoShown(!isWorkspaceInfoShown);
  }
  const [isSettingsShown, setIsSettingsShown] = useState(false);
  function toggleIsSettingsShown() {
    setIsSettingsShown(!isSettingsShown);
  }
  const [isOfferInfoShown, setIsOfferInfoShown] = useState(false);
  function toggleIsOfferInfoShown() {
    setIsOfferInfoShown(!isOfferInfoShown);
  }
  const [selectedNodeProps, setSelectedNodeProps] = useState<any>();
  const [selectedNodeData, setSelectedNodeData] = useState<any>();
  const [isNodeDetailsShown, setIsNodeDetailsShown] = useState<boolean>(false);
  function toggleIsNodeDetailsShown() {
    setIsNodeDetailsShown(!isNodeDetailsShown);
  }
  const [unmetPrerequisites, setUnmetPrerequisites] = useState<any[]>([]);
  const [isAuthModalShown, setIsAuthModalShown] = useState<boolean>(false);
  function toggleIsAuthModalShown() {
    setIsAuthModalShown(!isAuthModalShown);
  }
  async function handleRunPreFlightCheck(): Promise<{
    isReadyToRun: boolean;
    unmetPrerequisites: any[];
  }> {
    const {
      isReadyToRun,
      unmetPrerequisites
    } = await runPreFlightCheck(workspace);
    setUnmetPrerequisites(unmetPrerequisites);
    if (!isReadyToRun) {
      console.debug(`Workflow failed pre-flight checks. Unmet prerequisites: ${unmetPrerequisites.map(p => `[${p.nodeLabel}: ${p.prerequisite}] ${p.message}`).join(', ')}`);
      // throw new Error(
      //   `Workflow failed pre-flight checks. Unmet prerequisites: ${unmetPrerequisites
      //     .map((p) => `[${p.nodeLabel}: ${p.prerequisite}] ${p.message}`)
      //     .join(', ')}`,
      // );
      if (unmetPrerequisites.some(p => p.prerequisite === 'OAUTH_1_0A')) {
        setCanDeleteNode(false);
        setIsAuthModalShown(true);
      }
    }
    return {
      isReadyToRun,
      unmetPrerequisites
    };
  }
  const [workflowRunData, setWorkflowRunData] = useState({
    meta: 'Loading...'
  });
  const [isWorkflowRunDataLoading, setIsWorkflowRunDataLoading] = useState(true);
  const fetchWorkflowRunData = async () => {
    try {
      if (!userAddress || !workspaceUuid) return;
      let _workflowRunData = await getLatestWorkflowRun({
        ownerAddress: userAddress,
        workflowUuid: workspaceUuid
      });
      _workflowRunData = omit(_workflowRunData, ['workflowSnapshotJson']);
      _workflowRunData = {
        ..._workflowRunData,
        output: Object.fromEntries(Object.entries(_workflowRunData.output).map(([key, value]) => {
          return [key, omit(value, ['oasRequestSchema'])];
        }))
      };
      setWorkflowRunData(_workflowRunData);
    } catch (error) {
      console.error('Error fetching workflow task run data: ', error);
    } finally {
      setIsWorkflowRunDataLoading(false);
    }
  };
  useEffect(() => {
    fetchWorkflowRunData();
  }, [userAddress, workspaceUuid]);
  return <FlowContext.Provider value={{
    isLoading,
    isTestRunning,
    setIsTestRunning,
    workspace,
    fetchWorkspace,
    canDeleteNode,
    setCanDeleteNode,
    isWorkspaceInputsShown,
    setIsWorkspaceInputsShown,
    toggleIsWorkspaceInputsShown,
    onChangeWorkspaceInputs,
    workspaceInputsError,
    workspaceInputsStr,
    workspaceInputsJson,
    isWorkspaceInfoShown,
    setIsWorkspaceInfoShown,
    toggleIsWorkspaceInfoShown,
    isSettingsShown,
    setIsSettingsShown,
    toggleIsSettingsShown,
    isOfferInfoShown,
    setIsOfferInfoShown,
    toggleIsOfferInfoShown,
    selectedNodeProps,
    setSelectedNodeProps,
    selectedNodeData,
    setSelectedNodeData,
    isNodeDetailsShown,
    setIsNodeDetailsShown,
    toggleIsNodeDetailsShown,
    unmetPrerequisites,
    handleRunPreFlightCheck,
    isAuthModalShown,
    setIsAuthModalShown,
    toggleIsAuthModalShown,
    nodes,
    setNodes,
    onNodesChange,
    edges,
    setEdges,
    onEdgesChange,
    undo,
    redo,
    saveState,
    workflowRunData,
    isWorkflowRunDataLoading,
    fetchWorkflowRunData
  }} data-sentry-element="unknown" data-sentry-component="FlowProvider" data-sentry-source-file="FlowContext.tsx">
      {children}
    </FlowContext.Provider>;
};