import { AWS_S3_BUCKET } from '#/config/constants';
import { s3 } from '#/services/s3';
import { parseJson } from '#/utils/json';

import { CredentialType, getAuthFilePath } from './getAuthFilePath';

export interface GetAuthFileParams {
  address: string;
  service: string;
  credentialType: CredentialType;
  accountId?: string;
}

export async function getUserAuthFile({
  address,
  service,
  credentialType,
  accountId,
}: GetAuthFileParams): Promise<any> {
  const authFilePath = getAuthFilePath({ address, service, credentialType, accountId });

  console.debug('[getUserAuthFile] authFilePath:', authFilePath);

  const getObjectParams = {
    Bucket: AWS_S3_BUCKET,
    Key: authFilePath,
  };

  console.debug('[getUserAuthFile] getObjectParams:', getObjectParams);

  try {
    const authFileData = await s3.getObject(getObjectParams).promise();
    console.debug('[getUserAuthFile] authFileData:', authFileData);
    if (authFileData.Body) {
      const authFile = parseJson(
        typeof authFileData.Body !== 'string'
          ? authFileData.Body.toString()
          : authFileData.Body
      );
      console.debug(`[getUserAuthFile] Auth file:`, authFile);
      return authFile;
    }
  } catch (error: any) {
    console.error(`Error getting user auth file: ${error}`);
    throw new Error(`Error getting user auth file \`${authFilePath}\`: ${error.message}`);
  }
}

export default getUserAuthFile;
