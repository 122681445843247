import { useState } from 'react';
import { FaCode } from 'react-icons/fa6';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { bigintReplacer } from '#/utils/json';
import { HoverCard, HoverCardTrigger, HoverCardContent } from '~/ui/hover-card';
import Spinner from '~/ui/spinner';
export function CodeHoverCard({
  id,
  inputId,
  data = {},
  isLoading = false,
  title = '',
  Icon = FaCode,
  onUpload,
  fileType = 'json',
  downloadFileName = 'data',
  externalLink
}: {
  id?: string;
  inputId?: string;
  data?: Record<string, unknown>;
  isLoading?: boolean;
  title?: string;
  Icon?: React.ComponentType;
  onUpload?: (data: Record<string, unknown>) => Promise<void>;
  fileType?: string;
  downloadFileName?: string;
  externalLink?: string;
}) {
  const [isCardOpen, setIsCardOpen] = useState(false);
  const [isInteracting, setIsInteracting] = useState(false);
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);
  const handleCopyNodeDataToClipboard = () => {
    const dataStr = JSON.stringify(data, bigintReplacer, 2);
    navigator.clipboard.writeText(dataStr).then(() => {
      setShowCopiedMessage(true);
      setTimeout(() => {
        setShowCopiedMessage(false);
      }, 3000); // Show the message for 3 seconds
    }, err => {
      console.error('Could not copy text: ', err);
    });
  };
  const handleDownloadData = () => {
    const dataStr = JSON.stringify(data, bigintReplacer, 2);
    const blob = new Blob([dataStr], {
      type: 'application/json'
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${downloadFileName}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };
  async function handleUploadData(event: React.ChangeEvent<HTMLInputElement>) {
    if (onUpload == null) {
      console.error('onUpload function is not provided.');
      return;
    }
    const file = event?.target?.files?.[0];
    console.debug('[handleUploadData]', {
      file
    });
    if (file && file.type === `application/${fileType}`) {
      const reader = new FileReader();
      reader.onload = async e => {
        try {
          const uploadedData = JSON.parse(e?.target?.result?.toString() ?? '{}');
          await onUpload(uploadedData);
          setIsInteracting(false);
        } catch (err) {
          console.error('Invalid file format: ', err);
        }
      };
      reader.readAsText(file);
      console.debug('[handleUploadData]', {
        reader
      });
    } else {
      console.error(`Please upload a valid ${fileType} file.`);
    }
  }
  return <HoverCard open={isCardOpen} onOpenChange={setIsCardOpen} data-sentry-element="HoverCard" data-sentry-component="CodeHoverCard" data-sentry-source-file="CodeHoverCard.tsx">
      {onUpload ? <input type="file" accept={`.${fileType}`} className="hidden" id={inputId} onChange={handleUploadData} onClick={() => setIsInteracting(true)} /> : null}
      <HoverCardTrigger id={id} onMouseEnter={() => setIsCardOpen(true)} data-sentry-element="HoverCardTrigger" data-sentry-source-file="CodeHoverCard.tsx">
        <Icon className="h-4 w-4" data-sentry-element="Icon" data-sentry-source-file="CodeHoverCard.tsx" />
      </HoverCardTrigger>
      {isCardOpen && <HoverCardContent className="w-[50vw] max-h-[80vh] overflow-scroll" onMouseLeave={() => {
      if (!isInteracting) {
        setIsCardOpen(false);
      }
    }}>
          <div className="flex space-x-2 mb-2">
            <button className="text-slate-100" onClick={handleCopyNodeDataToClipboard}>
              {showCopiedMessage ? 'Copied!' : 'Copy Data'}
            </button>
            <button className="text-slate-100" onClick={handleDownloadData}>
              Download Data
            </button>
            {onUpload ? <>
                <label htmlFor={inputId} className="text-slate-100 cursor-pointer">
                  Upload Data
                </label>
              </> : null}
            {externalLink && <a href={externalLink} target="_blank" rel="noopener noreferrer" className="text-slate-100 flex items-center">
                View <FaExternalLinkAlt className="ml-1 h-3 w-3" />
              </a>}
          </div>
          {title ? <p className="text-slate-100 text-sm mb-2">{title}</p> : null}
          <pre className="whitespace-pre-wrap text-xs">
            {isLoading ? <div className="w-8 h-8">
                <Spinner />
              </div> : JSON.stringify(data, bigintReplacer, 2)}
          </pre>
        </HoverCardContent>}
    </HoverCard>;
}
export default CodeHoverCard;