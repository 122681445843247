// Config
// /auth/[service]/[credentialType].json
// /users/[address]/auth/[service]/[credentialType].json
// authType: apikey, basicAuth, jwt, oauth1Config, oauth2Config

// Account Credential
// /users/[address]/auth/[service]/[accountId]/[credentialType].json
// credentialType: apikey, basicAuth, jwt, oauth1AccessToken, oauth2AccessToken

// All possible credential types examples as a single file tree:
// /auth
// ├── [service]
// │   ├── apikey.json
// │   ├── basicAuth.json
// │   ├── jwt.json
// │   ├── oauth1Config.json
// │   └── oauth2Config.json
// └── [another_service]
//     └── ...
//
// /users
// └── [address]
//     └── auth
//         └── [service]
//             ├── apikey.json
//             ├── basicAuth.json
//             ├── jwt.json
//             ├── oauth1Config.json
//             ├── oauth2Config.json
//             └── accounts
//                 └── [accountId]
//                     ├── apikey.json
//                     ├── basicAuth.json
//                     ├── jwt.json
//                     ├── oauth1AccessToken.json
//                     └── oauth2AccessToken.json


export enum CredentialType {
  ApiKey = 'apikey',
  BasicAuth = 'basicAuth',
  JWT = 'jwt',
  OAuth1Config = 'oauth1Config',
  OAuth2Config = 'oauth2Config',
  OAuth1AccessToken = 'oauth1AccessToken',
  OAuth2AccessToken = 'oauth2AccessToken'
}

export function isServiceAuthCredential(credentialType: CredentialType): boolean {
  return credentialType === CredentialType.OAuth1Config || credentialType === CredentialType.OAuth2Config;
}

export function getAuthFilePath({
  address,
  service,
  credentialType,
  accountId,
}: {
  address: string;
  service: string;
  credentialType: CredentialType;
  accountId?: string | null;
}): string {
  // For config credentials, check user directory first if address is provided
  const isConfig = credentialType === CredentialType.OAuth1Config ||
    credentialType === CredentialType.OAuth2Config;

  if (address && isConfig) {
    const userPath = `/users/${address}/auth/${service}/${credentialType}.json`;
    return userPath;
  }

  const basePath = `/users/${address}/auth/${service}`;

  if (isServiceAuthCredential(credentialType)) {
    return `${basePath}/${credentialType}.json`;
  }

  if (accountId) {
    return `${basePath}/accounts/${accountId}/${credentialType}.json`;
  }

  // For service config credentials (non-account specific)
  return `${basePath}/${credentialType}.json`;
}

export function getBucketAuthFilePath({
  service,
  credentialType,
  accountId,
}: {
  service: string;
  credentialType: CredentialType;
  accountId?: string | null;
}): string {
  const basePath = `/auth/${service}`;

  if (accountId) {
    return `${basePath}/accounts/${accountId}/${credentialType}.json`;
  }

  // For service config credentials (non-account specific)
  return `${basePath}/${credentialType}.json`;
}
