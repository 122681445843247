import { AWS_S3_BUCKET } from '#/config/constants';
import { s3 } from '#/services/s3';

export default async function checkFileExists(args: {
  s3ObjectKey: string;
}): Promise<boolean> {
  const { s3ObjectKey } = args;
  try {
    await s3.headObject({ Bucket: AWS_S3_BUCKET, Key: s3ObjectKey }).promise();
    return true;
  } catch (err: any) {
    if (err.code === 'NotFound') {
      return false;
    } else {
      console.error(`Error checking file existence: ${err}`);
      return false;
    }
  }
}
