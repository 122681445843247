import superjson from 'superjson';

import { AWS_S3_BUCKET } from '#/config/constants';
import { s3 } from '#/services/s3';
import checkFileExists from '#/services/s3/actions/checkFileExists';

import putS3Object from '../putS3Object';

export async function upsertJsonFile<T = Record<string, any>>(args: {
  s3ObjectKey: string;
  updatedJson: Partial<T>;
}): Promise<T> {
  try {
    const date = new Date();
    // const timestampMs = date.getTime();
    const timestampIso = date.toISOString();

    const { s3ObjectKey, updatedJson } = args;

    const getObjectParams = {
      Bucket: AWS_S3_BUCKET,
      Key: s3ObjectKey,
    };
    // console.debug('[upsertJsonFile] getObjectParams: ' + stringifyJson(getObjectParams));

    let currentJson = {};
    try {
      if (await checkFileExists({ s3ObjectKey })) {
        const response = await s3.getObject(getObjectParams).promise();
        const parsedResponse = superjson.parse(
          (typeof response?.Body !== 'string'
            ? response?.Body?.toString()
            : response?.Body) ?? '{}',
        );
        currentJson = parsedResponse ?? {};
      }
    } catch (error: any) {
      console.error(error);
      throw new Error(`Error getting JSON file: ${error.message}`);
    }

    const mergedJson = {
      ...currentJson,
      ...updatedJson,
      updatedAt: timestampIso,
    };

    const newBody = superjson.stringify(mergedJson);
    // console.debug('[upsertJsonFile] newBody:', newBody);

    await putS3Object({
      Bucket: AWS_S3_BUCKET,
      Key: s3ObjectKey,
      Body: newBody,
    });

    return mergedJson as T;
  } catch (error: any) {
    console.error(error);
    throw new Error(`Error upserting JSON file: ${error.message}`);
  }
}
export default upsertJsonFile;
