/**
 * Tracks a history of elements, allowing the user to undo and redo.
 * This class is immutable, so it can be used in `useState()` hooks.
 */
export class History<T> {
  private readonly history: T[];
  private readonly currentIndex: number;

  private constructor(history: T[], currentIndex: number) {
    this.history = history;
    this.currentIndex = currentIndex;
  }
  public static new<T>() {
    return new History<T>([], -1);
  }

  /**
   * Gets the current item in this history.
   * @returns the current item, or undefined if there are no items
   */
  current(): T | undefined {
    if (this.currentIndex === -1) {
      return undefined;
    }
    return this.history[this.currentIndex];
  }

  /**
   * Creates a new History with a new change added.
   * Writing a new change will erase any future history, so {@link redo} will fail after calling `write()`.
   * @param t the item to add to the end of history
   * @returns new history
   */
  write(t: T): History<T> {
    return new History<T>(
      [...this.history.slice(0, this.currentIndex + 1), t],
      this.currentIndex + 1
    );
  }

  /**
   * Creates a new History with the last change undone.
   * The undone change is still present in the history and can be recovered with {@link redo}.
   * If there was no change to undo, nothing happens.
   * @returns success if there was a change to be undone, and the new history
   */
  undo(): { success: boolean, history: History<T> } {
    if (this.currentIndex === -1) {
      return {
        success: false,
        history: this
      };
    }
    return {
      success: true,
      history: new History<T>(
        this.history,
        this.currentIndex - 1
      )
    };
  }

  /**
   * Creates a new History, redoing the last {@link undo}.
   * If there was no change to redo, nothing happens.
   * @returns success if there was a change to be redone, and the new history
   */
  redo(): { success: boolean, history: History<T> } {
    if (this.currentIndex === this.history.length - 1) {
      return {
        success: false,
        history: this
      };
    }
    return {
      success: true,
      history: new History<T>(
        this.history,
        this.currentIndex + 1
      )
    };
  }

}

export default function newHistory<T>() {
  return History.new<T>();
}
