import React from 'react';
interface ErrorBoundaryProps {
  children: React.ReactNode;
}
interface ErrorBoundaryState {
  hasError: boolean;
  error?: Error;
}
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false
    };
  }
  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return {
      error,
      hasError: true
    };
  }
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // You can log the error to an error reporting service
    console.error('Uncaught error:', error, errorInfo);
  }
  render() {
    if (this.state.hasError) {
      return <div className="text-red-500 p-4 border border-red-500 rounded" data-sentry-component="ErrorBoundary" data-sentry-source-file="ErrorBoundary.tsx">
          <h2 className="font-bold">Something went wrong:</h2>
          <pre className="mt-2 text-sm">{this.state.error?.message}</pre>
        </div>;
    }
    return <>{this.props.children}</>;
  }
}
export default ErrorBoundary;