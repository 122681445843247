import crypto from 'crypto';
export function deterministicUuid(str: string) {
  const hash = crypto.createHash('sha256').update(str).digest('hex');
  const uuid = [
    hash.substr(0, 8),
    hash.substr(8, 4),
    hash.substr(12, 4),
    hash.substr(16, 4),
    hash.substr(20, 12),
  ].join('-');
  return uuid;
}
export default deterministicUuid;
