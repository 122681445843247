'use client';

import React, { useState, useEffect } from 'react';
import { useRouter, usePathname } from 'next/navigation';
import Image from 'next/image';
import { ReactFlowProvider } from 'reactflow';
import { toast } from 'react-toastify';
import { TbSquareAsterisk } from 'react-icons/tb';
import { mapValues, omit, pick, set } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { FlowProvider, useFlowContext } from '#/providers/FlowContext';
import { useUserContext } from '#/providers/UserContext';
import { User } from '#/services/user';
import { IWorkspace, RunStatus } from '#/types/workspace';
import CodeHoverCard from '~/Flow/CodeHoverCard';
import Flow from '~/Flow/Flow';
import { AddressBar } from '~/Layout/AddressBar';
import LoadingIndicator from '~/ui/LoadingIndicator';
import dynamic from 'next/dynamic';
const AuthModal = dynamic(() => import('~/Flow/AuthModal'), {
  ssr: false
});
const NodeDetailsModal = dynamic(() => import('~/Flow/NodeDetailsModal'), {
  ssr: false
});
const WorkspaceContextModal = dynamic(() => import('~/Flow/WorkspaceContextModal'), {
  ssr: false
});
const WorkspaceInfoModal = dynamic(() => import('~/Flow/WorkspaceInfoModal'), {
  ssr: false
});
const WorkspaceOfferInfoModal = dynamic(() => import('~/Flow/WorkspaceOfferInfoModal/WorkspaceOfferInfoModal'), {
  ssr: false
});
function workspaceDataFromWorkspace(workspace: any = {}) {
  // remove workspace.nodes[i].data.oasRequestSchema from all nodes
  return {
    ...workspace,
    nodes: (workspace?.nodes ?? []).map((node: any) => {
      const data = {
        ...node.data
      };
      if (data.oasRequestSchema) {
        delete data.oasRequestSchema;
      }
      if (data.output) {
        delete data.output;
      }
      return {
        ...node,
        data
      };
    })
  };
}
const pulseAnimation = `
  @keyframes pulse {
    0%, 100% {
      transform: scale(1);
      box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }
    50% {
      transform: scale(1.1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
  }
`;
function CircleIndicator(props: {
  status?: RunStatus;
  isActive?: boolean;
}) {
  const {
    status
  } = props;
  const {
    isActive = status === 'RUNNING'
  } = props;
  const colorClass = status == null || status === 'PENDING' ? 'bg-gray-400' : status === 'RUNNING' ? 'bg-yellow-400' : status === 'FAILED' ? 'bg-red-400' : status === 'SUCCESS' ? 'bg-green-400' : 'bg-gray-400';
  return <>
      <style>{pulseAnimation}</style>
      <div className={`w-3 h-3 rounded-full ${colorClass} ${isActive ? 'animate-pulse' : ''}`} style={{
      animation: isActive ? 'pulse 2s infinite' : 'none'
    }} />
    </>;
}
function workspaceDataFromUploadedWorkspace(oldWorkspace: any, uploadedData: any) {
  return {
    ...oldWorkspace,
    ...uploadedData,
    id: oldWorkspace.id,
    uuid: oldWorkspace.uuid,
    owner: oldWorkspace.owner,
    webhookUrl: null
  };
}
function WorkspaceView({
  workspaceUuid
}: {
  workspaceUuid: string;
}) {
  const router = useRouter();
  const {
    userSession,
    user
  } = useUserContext();
  const userAddress = userSession?.address;
  const {
    isLoading,
    isTestRunning,
    workspace,
    fetchWorkspace,
    isWorkspaceInputsShown,
    toggleIsWorkspaceInputsShown,
    onChangeWorkspaceInputs,
    workspaceInputsError,
    workspaceInputsStr,
    workspaceInputsJson,
    isWorkspaceInfoShown,
    isSettingsShown,
    isOfferInfoShown,
    isNodeDetailsShown,
    isAuthModalShown,
    workflowRunData,
    isWorkflowRunDataLoading,
    fetchWorkflowRunData
  } = useFlowContext();
  const workspaceData = workspaceDataFromWorkspace(workspace);
  const [isSaving, setIsSaving] = useState(false);
  async function saveWorkspace(updatedWorkspace: Partial<IWorkspace>) {
    try {
      if (userAddress == null || user == null) {
        toast.error('Error saving workspace: User not found');
        return;
      }
      setIsSaving(true);
      console.debug('[saveWorkspace] Saving workspace: ', updatedWorkspace);
      const result = await user.updateWorkspace({
        workspaceUuid: workspaceUuid ?? uuidv4(),
        updatedWorkspace: {
          ...workspace,
          ...updatedWorkspace
        }
      });
      console.debug('Saved workspace: ', result);
      toast.success('Workspace saved successfully!');
    } catch (error: any) {
      console.error('Error saving workspace: ', error);
      toast.error(`Save failed! Error: ${error?.message}`, {
        autoClose: false,
        closeOnClick: false,
        draggable: false
      });
    } finally {
      setIsSaving(false);
    }
  }
  const [isShiftPressed, setIsShiftPressed] = useState(false);
  function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
    setIsShiftPressed(event.shiftKey);
  }
  function handleKeyUp(event: React.KeyboardEvent<HTMLDivElement>) {
    setIsShiftPressed(event.shiftKey);
  }
  function handleSelect(event: React.KeyboardEvent<HTMLDivElement>) {
    if (isShiftPressed) {
      event.preventDefault(); // Prevents text selection
    }
  }
  const [workspaceContext, setWorkspaceContext] = useState({});
  useEffect(() => {
    setWorkspaceContext(workspace?.context ?? {});
  }, [JSON.stringify(workspace?.context)]);
  async function updateWorkspaceContext({
    context
  }: {
    context: Record<string, any>;
  }) {
    if (workspaceUuid == null || workspaceUuid === '' || userAddress == null) {
      return;
    }
    const user = new User({
      address: userAddress
    });
    await user.updateWorkspaceContext({
      workspaceUuid,
      updatedWorkspaceContext: context
    });
    window.location.reload();
    toast.success('Workspace context updated successfully!');
  }
  function checkWorkspaceData(data: any) {
    return data != null && data.nodes != null && data.edges != null && data.nodes.length > 0 && data.edges.length > 0;
  }
  async function onUploadWorkspaceData(uploadedData: any) {
    console.debug('onUploadWorkspaceData', uploadedData);
    if (userAddress == null || user == null) {
      return;
    }
    const newWorkspace = workspaceDataFromUploadedWorkspace(workspace, uploadedData?.json ?? uploadedData);
    if (!checkWorkspaceData(newWorkspace)) {
      return;
    }
    await saveWorkspace(newWorkspace);
    await fetchWorkspace();
  }
  if (isLoading) {
    return <div className="bg-transparent h-screen w-screen flex">
        <LoadingIndicator />
        <div className="visible md:invisible w-full h-full fixed z-50 grid items-center justify-center pointer-events-auto bg-primary">
          <div className="rounded-lg bg-base-100 p-8 flex flex-col items-center gap-4 relative pointer-events-auto w-80">
            <div className="relative h-[80px] w-[80px]">
              <Image src="/linkit.svg" alt="linkit logo" height={80} width={80} />
            </div>
            <div className="text-xs max-w-sm flex flex-col gap-2">
              <p>
                Mobile support is not implemented. Please use the app on a
                larger device. Sorry!
              </p>
            </div>
          </div>
        </div>
      </div>;
  }
  if (workspace == null) {
    return <div className="bg-transparent h-screen w-screen">
        <main className="w-full h-full relative">
          <div className="w-full h-full fixed z-0">
            <p className="text-primary">Error</p>
          </div>
        </main>
      </div>;
  }

  // const isDevMode = true;

  return <div onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} onSelect={handleSelect} className="bg-transparent h-screen w-screen" data-sentry-component="WorkspaceView" data-sentry-source-file="page.tsx">
      <main className="w-full h-full relative">
        <div className="z-10 fixed w-full invisible md:visible">
          <div className="mx-auto max-w-4xl space-y-2 px-2 pt-20 lg:py-9 lg:px-9">
            <div className="rounded-lg bg-vc-border-gradient p-px shadow-lg shadow-black/20">
              <div className="rounded-lg bg-black">
                <AddressBar isInWorkspace={true} data-sentry-element="AddressBar" data-sentry-source-file="page.tsx" />
              </div>
            </div>
            <div className="flex flex-row space-between items-center text-md">
              <h1 className="flex-grow text-gray-100">
                <span className="text-gray-400">Workspace:</span>{' '}
                {workspace.name}
              </h1>
              <div className="flex items-center space-x-4">
                <CodeHoverCard id="workspace-card" inputId="workspace-input" data={workspaceData} title={workspace.name} downloadFileName={`${workspace.name}.json`} onUpload={onUploadWorkspaceData} data-sentry-element="CodeHoverCard" data-sentry-source-file="page.tsx" />
                {/* TODO: workspace vs. latest saved workspace snapshot */}
                {/* <CodeHoverCard data={workspace} /> */}
                <CodeHoverCard id="workflow-run-card" data={workflowRunData} isLoading={isWorkflowRunDataLoading} title="Most Recent `WorkflowRun`" Icon={TbSquareAsterisk} data-sentry-element="CodeHoverCard" data-sentry-source-file="page.tsx" />
                <CircleIndicator status={workflowRunData?.status} data-sentry-element="CircleIndicator" data-sentry-source-file="page.tsx" />
                <button onClick={toggleIsWorkspaceInputsShown} className="p-2 rounded-md bg-opacity-50 bg-slate-700 hover:bg-slate-300 hover:bg-opacity-50 hover:cursor-pointer hover:text-slate-700">
                  Workspace Inputs
                </button>
              </div>
            </div>
            <div className="flex flex-row justify-end items-center text-md">
              {isWorkspaceInputsShown ? <div className="w-1/3 flex flex-col">
                  <textarea value={workspaceInputsStr ?? ''} onChange={event => onChangeWorkspaceInputs(event.target.value)} className="" />
                  {workspaceInputsError.length > 0 ? <p className="">{workspaceInputsError}</p> : null}
                </div> : null}
            </div>
          </div>
        </div>
        {/* <div
          className={`w-1/2 h-full fixed z-10 ${
            isDevMode ? 'visible' : 'invisible'
          }`}
         >
          TODO
         </div> */}
        <div className="w-full h-full fixed z-0">
          {workspace != null && workspaceUuid != null ? <Flow user={user!} workspaceUuid={workspaceUuid} fetchWorkflowRunData={fetchWorkflowRunData} isSaving={isSaving} setIsSaving={setIsSaving} saveWorkspace={saveWorkspace} /> : null}
        </div>
        {isWorkspaceInfoShown ? <WorkspaceInfoModal workspace={workspace} /> : isSettingsShown ? <WorkspaceContextModal workspaceContext={workspaceContext} updateWorkspaceContext={updateWorkspaceContext} /> : isOfferInfoShown ? <WorkspaceOfferInfoModal workspace={workspace} /> : isNodeDetailsShown ? <NodeDetailsModal /> :
      // ) : isAuthModalShown ? (
      //   <AuthModal />
      null}
        <div className="visible md:invisible fixed grid items-center justify-center h-full w-full z-[9999] pointer-events-auto bg-primary">
          <div className="rounded-lg bg-base-100 p-8 flex flex-col items-center gap-4 relative pointer-events-auto w-80">
            <div className="relative h-[80px] w-[80px]">
              <Image src="/linkit.svg" alt="linkit logo" height={80} width={80} data-sentry-element="Image" data-sentry-source-file="page.tsx" />
            </div>
            <div className="text-xs max-w-sm flex flex-col gap-2">
              <p>
                Mobile support is not implemented. Please use the app on a
                larger device. Sorry!
              </p>
            </div>
          </div>
        </div>
      </main>
    </div>;
}
export default function Page() {
  const pn = usePathname();
  const np = pn?.split('/').slice(1);
  const slug = np?.toString() || '';
  const workspaceUuid = slug;
  return <ReactFlowProvider data-sentry-element="ReactFlowProvider" data-sentry-component="Page" data-sentry-source-file="page.tsx">
      <FlowProvider data-sentry-element="FlowProvider" data-sentry-source-file="page.tsx">
        <WorkspaceView {...{
        workspaceUuid
      }} data-sentry-element="WorkspaceView" data-sentry-source-file="page.tsx" />
      </FlowProvider>
    </ReactFlowProvider>;
}