// Description: This file is the entry point for all the actions related to the Simplifi_S3 service
import checkFileExists from './checkFileExists';
import checkFolderExists from './checkFolderExists';
import createOrRetrieveUserFolder from './createOrRetrieveUserFolder';
import getDefaultProfile from './getDefaultProfile';
import getProfile from './getProfile';
import updateProfile from './updateProfile';
import getSecrets from './getSecrets';
import updateSecrets from './updateSecrets';

const Simplifi_S3 = {
  checkFileExists,
  checkFolderExists,
  createOrRetrieveUserFolder,
  getDefaultProfile,
  getProfile,
  updateProfile,
  getSecrets,
  updateSecrets,
};

export default Simplifi_S3;
