import { last, sortBy } from 'lodash';

import { PATH_USERS, pathJoin } from '#/services/s3/filepaths';
import listAllObjects from '#/services/s3/actions/listAllObjects';

import getWorkflowRun from './getWorkflowRun';
import { IWorkflowRun } from '@/src/types/workspace';

function isWorkflowRunFileKey(fileKey: string) {
  return /runs\/[0-9a-f-]+\.json$/.test(fileKey);
}

function runIdFromWorkflowRunFileKey(fileKey: string) {
  const runIdPattern = /runs\/([0-9a-f-]+)\.json/;
  const match = fileKey.match(runIdPattern);
  return match?.[1];
}

export async function getLatestWorkflowRunId({
  ownerAddress,
  workflowUuid,
}: {
  ownerAddress: string;
  workflowUuid: string;
}): Promise<string> {
  const workflowRunsBaseKey = pathJoin(PATH_USERS, String(ownerAddress), 'workflows', String(workflowUuid), 'runs', '/');
  const objects = await listAllObjects({ folderKey: workflowRunsBaseKey });
  const workflowRunFileKeys = objects.filter(fileKey => isWorkflowRunFileKey(fileKey));
  const runs = await Promise.all(
    workflowRunFileKeys.map(async fileKey => {
      const runId = runIdFromWorkflowRunFileKey(fileKey);
      if (!runId) return null;
      const run = await getWorkflowRun({
        ownerAddress,
        workflowUuid,
        runId,
      });
      return { runId, updatedAt: run?.updatedAt };
    })
  );
  const validRuns = runs.filter((run): run is { runId: string, updatedAt: string } =>
    run !== null && run.updatedAt !== undefined
  );
  const latestRun = last(sortBy(validRuns, 'updatedAt'));
  return latestRun?.runId ?? '';
}

export async function getLatestWorkflowRun({
  ownerAddress,
  workflowUuid,
}: {
  ownerAddress: string;
  workflowUuid: string;
}): Promise<IWorkflowRun | null> {
  const runId = await getLatestWorkflowRunId({
    ownerAddress,
    workflowUuid,
  });
  const json = await getWorkflowRun({
    ownerAddress,
    workflowUuid,
    runId,
  });
  return json ?? null;
}

export default getLatestWorkflowRun;
