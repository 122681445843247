//
// TODO: Implement debugging levels to manage granularity
//

//
// Strict mode prevents implicit access to the calling function and its name, so the calling function
// must be explicitly passed.
//
// See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Strict_mode#removal_of_stack-walking_properties
//
export function debug(caller: Function | string | null, ...args: any[]) {
  if (caller) {
    console.debug(`[${typeof caller === 'function' ? caller.name : caller}]`, ...args);
  } else {
    console.debug(...args);
  }
}
