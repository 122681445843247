'use client';

import React, { ReactNode } from 'react';
import { Handle, Position, NodeProps, useReactFlow } from 'reactflow';
import Image from 'next/image';
// import { useQuery, useQueryClient } from '@tanstack/react-query';

import { Pencil2Icon, TrashIcon } from '@radix-ui/react-icons';
import { FiCloud } from 'react-icons/fi';
import { useFlowContext } from '#/providers/FlowContext';
import { ActionNodeData, INodeData, TriggerNodeData } from '#/types/workspace';
import ContextMenu from '~/ui/context-menu';
import ErrorBoundary from '~/ErrorBoundary';
import { isEmpty } from 'lodash';

// TODO

// export type FlowNodeType = {
//   id: string;
//   title: string;
//   description: string;
//   trigger?: NodeTrigger;
//   action?: NodeActon;
//   icons?: ReactNode;
//   subline?: string;
// };

// export type NodeTrigger = {
//   default: IO;
//   mock: NA;
//   custom: NA;
//   manual: NA;
//   cron: NA;
//   webhook: NA;
//   event: NA;
//   schedule: NA;
//   api: NA;
//   email: NA;
//   sms: NA;
//   slack: NA;
//   telegram: NA;
//   discord: NA;
//   push: NA;
//   facebook: NA;
//   twitter: NA;
//   socket: Na;
//   condition: boolean;
// };
// export type NodeActon = {};

// export type DefaultNodeData = {
//   id: string;
//   title: string;
//   type: FlowNodeType;
//   description: string;
//   trigger?: NodeTrigger;
//   action?: NodeActon;
//   icons?: [icon: [IconType]];
//   subline?: string;
// };

export interface TurboNodeData {
  title: string;
  icon?: ReactNode;
  subline?: string;
  detail?: string;
  serviceType?: string;
  actionType?: string;
  isLateral?: boolean;
}
function NodeBody(props: {
  nodeProps: any;
  nodeData: INodeData;
  // updateNodeData: any;

  icons?: string[];
  title?: string;
  subline?: string;
  detail?: string;
}) {
  const {
    nodeProps,
    nodeData,
    // updateNodeData,
    icons,
    title,
    subline,
    detail
  } = props;
  const {
    canDeleteNode,
    setCanDeleteNode,
    setSelectedNodeProps,
    setSelectedNodeData,
    // setUpdateSelectedNodeData,
    setIsNodeDetailsShown
  } = useFlowContext();
  const {
    deleteElements
  } = useReactFlow();
  function handleEditClick() {
    setSelectedNodeProps(nodeProps);
    setSelectedNodeData(nodeData);
    // setUpdateSelectedNodeData(updateNodeData);
    setCanDeleteNode(false);
    setIsNodeDetailsShown(true);
  }
  function handleDeleteClick() {
    setSelectedNodeProps(undefined);
    setSelectedNodeData(undefined);
    deleteElements({
      nodes: [{
        id: nodeProps?.id
      }]
    });
  }
  const statusClass = `status-${nodeData?.status}`;
  const isSwitchTrigger = nodeData.type === 'trigger' && nodeData.triggerType === 'switch';
  const cases: string[] = isSwitchTrigger ? (nodeData.inputValues?.cases ?? '').split(',') : [];
  const switchCase = nodeData?.output?.switchCase;
  const isSwitchCaseBlank = typeof switchCase !== 'string' || isEmpty(switchCase);
  const handleType = nodeData.dependencyType === 'ANY' ? 'diamond' : 'circle';
  const handleColor = nodeData.dependencyType === 'ANY' ? 'orange' : 'green';

  // Hash function to generate a color from a string
  const hashStringToColor = (str: string = ''): string => {
    const colors = ['black', 'silver', 'gray', 'white', 'maroon', 'red', 'purple', 'fuchsia', 'green', 'lime', 'olive', 'yellow', 'navy', 'blue', 'teal', 'aqua', 'orange', 'aliceblue', 'antiquewhite', 'aquamarine', 'azure', 'beige'];
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const index = Math.abs(hash) % colors.length;
    return colors[index];
  };
  let borderColor;
  if (nodeData?.module) {
    borderColor = hashStringToColor(nodeData.module);
  }
  return <div className={`wrapper ${borderColor ? '' : 'gradient'} min-w-[160px] min-h-[90px] ${statusClass}`} style={{
    backgroundColor: borderColor
  }} data-sentry-component="NodeBody" data-sentry-source-file="TurboNode.tsx">
      <div className="inner">
        <div className="body flex flex-col justify-center items-start">
          <div className="w-full flex flex-row justify-between items-center space-x-2">
            {/* {icons && icons.length > 0 ? (
              <div className="icons">
                {icons.map((cl) => (
                  <DynamicIcon className={`${cl}`} />
                ))}
              </div>
             ) : null} */}
            <div className="flex flex-row justify-between items-center space-x-2">
              <div className="title">{title}</div>
              <button title="Edit Node" onClick={handleEditClick} className="top-[-2px]">
                <Pencil2Icon className="top-[-2px]" data-sentry-element="Pencil2Icon" data-sentry-source-file="TurboNode.tsx" />
              </button>
            </div>
            <button title="Delete Node" onClick={handleDeleteClick} className="top-[-2px]" disabled={canDeleteNode !== true}>
              {/* <TrashIcon className="top-[-2px]" color="#ef4444" /> */}
              <TrashIcon className="top-[-2px]" color="#fff" data-sentry-element="TrashIcon" data-sentry-source-file="TurboNode.tsx" />
            </button>
          </div>
          <div>{subline ? <div className="subline">{subline}</div> : null}</div>
          <div>{detail ? <div className="subline">{detail}</div> : null}</div>
        </div>
      </div>
      <Handle type="target" position={Position.Top} className={`react-flow__handle target ${handleType}`} style={{
      border: `1px solid black`,
      ...(handleType === 'diamond' && {
        borderRadius: 0,
        border: 'none',
        height: '18px',
        width: '18px',
        marginTop: '-1px',
        background: 'none',
        backgroundImage: "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-10 -10 120 120'><polygon points='50,0 100,50 50,100 0,50' fill='orange' stroke='black' stroke-width='5'/></svg>\")",
        backgroundSize: '90%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      })
    }} data-sentry-element="Handle" data-sentry-source-file="TurboNode.tsx" />
      {isSwitchTrigger ? <div className="absolute bottom-0 w-full px-4 flex flex-row space-x-6 justify-center items-center">
          {cases.map(caseValue => <div key={caseValue} className="flex flex-col justify-center items-center">
              <p className={`mb-1 ${isSwitchCaseBlank ? 'text-white' : switchCase === caseValue ? 'text-white' : 'text-gray-500'}`}>
                {caseValue}
              </p>
              <Handle type="source" position={Position.Bottom} id={caseValue} className="block left-auto right-auto transform-none" style={{
          border: '1px solid black'
        }} />
            </div>)}
        </div> : <Handle type="source" position={Position.Bottom} />}
    </div>;
}
export function TurboNode(props: NodeProps<TurboNodeData & (ActionNodeData | TriggerNodeData)>) {
  // // Access the client
  // const queryClient = useQueryClient();

  // const { workspace } = useFlowContext();

  const {
    id: nodeId,
    xPos,
    yPos,
    type: nodeType,
    data: nodeData
  } = props;
  const {
    uuid: nodeUuid,
    type: nodeDataType,
    logoUrl,
    icons,
    title,
    subline,
    detail
  } = nodeData;

  // // Queries
  // const query = useQuery({
  //   queryKey: ['workflows', workspace?.uuid, 'nodes', nodeUuid, 'latestTaskRun'],
  //   queryFn: async function getLatestWorkflowTaskRun() {
  //     const response = await fetch('/api/todos');
  //     const data = await response.json();
  //     return data;
  //   },
  // });

  // enum WorkflowOrientation {
  //   'VERTICAL' = 'VERTICAL',
  //   'LATERAL' = 'LATERAL',
  // }
  // const DEFAULT_WORKFLOW_ORIENTATION = WorkflowOrientation.VERTICAL;
  // const isLateral =
  //   nodeData?.isLateral ??
  //   DEFAULT_WORKFLOW_ORIENTATION === WorkflowOrientation.LATERAL;

  // const triggerType = nodeDataType !== 'trigger' ? null : nodeData?.triggerType;

  return <ErrorBoundary data-sentry-element="ErrorBoundary" data-sentry-component="TurboNode" data-sentry-source-file="TurboNode.tsx">
      <div className="relative">
        {/* <ContextMenu nodeProps={props} nodeData={nodeData}> */}
        {/* <div className="absolute l-[-16px] t-[-16px]"> */}
        {nodeId ?
      // <div className="corner-icon absolute left-2 top-2 z-10 border border-gray-400 rounded-full h-3.5 w-3.5 text-center align-center text-gray-400 text-[8px]">
      <div className="corner-icon cloud left-[-23px] z-10 border border-gray-400 rounded-full h-6 w-6 bg-[#111] flex flex-col items-center justify-center text-center align-center text-gray-400 text-xs">
            <span className="block w-full mt-[1px]">{nodeId}</span>
          </div> : null}
        <div className="corner-icon cloud gradient">
          <div>
            {logoUrl ? <Image unoptimized className="rounded-full" height={24} width={24} src={logoUrl} alt={title} /> : <FiCloud />}
          </div>
        </div>
        <NodeBody {...{
        nodeProps: props,
        nodeData,
        // updateNodeData,

        icons,
        title,
        subline,
        detail

        // latestTaskRun: query.data,
      }} data-sentry-element="NodeBody" data-sentry-source-file="TurboNode.tsx" />
        {/* </ContextMenu> */}
      </div>
    </ErrorBoundary>;

  // return (
  //   <>
  //     <DropdownMenu.Root>
  //       <DropdownMenu.Trigger
  //         asChild
  //         // onClick={getServiceActions}
  //       >
  //         <div className="cloud gradient">
  //           <div>{<CornerIcon />}</div>
  //         </div>
  //       </DropdownMenu.Trigger>
  //       <DropdownMenu.Portal>
  //         <DropdownMenu.Content
  //           className="DropdownMenuContent text-blue-500 bg-black  border-green-300"
  //           sideOffset={5}
  //         >
  //           <DropdownMenu.Item
  //             className="DropdownMenuItem hover:cursor-pointer"
  //             // onSelect={() => handleServiceSelect('zapier')}
  //           >
  //             Zapier
  //           </DropdownMenu.Item>
  //           <DropdownMenu.Item
  //             className="DropdownMenuItem hover:cursor-pointer"
  //             // onSelect={() => handleServiceSelect('twitter')}
  //           >
  //             Twitter
  //           </DropdownMenu.Item>

  //           <DropdownMenu.Arrow className="DropdownMenuArrow" />
  //         </DropdownMenu.Content>
  //       </DropdownMenu.Portal>
  //     </DropdownMenu.Root>

  //     <div className="wrapper gradient">
  //       <DropdownMenu.Root>
  //         <div className="inner">
  //           <div className="body">
  //             <DropdownMenu.Trigger asChild>
  //               {data.icon && (
  //                 <div className="icon">
  //                   {(data.icon === typeof IconBase && data.icon !== null) ||
  //                   undefined ? (
  //                     data.icon
  //                   ) : (
  //                     <FiCloud />
  //                   )}
  //                 </div>
  //               )}
  //             </DropdownMenu.Trigger>
  //             <DropdownMenu.Portal className=" border-blue-300">
  //               <DropdownMenu.Content
  //                 className="DropdownMenuContent text-blue-500 bg-black  border-green-300"
  //                 sideOffset={5}
  //               >
  //                 {/* {getServiceActions().map((action, index) => (
  //                   <DropdownMenu.Item
  //                     key={index}
  //                     className="DropdownMenuItem hover:cursor-pointer"
  //                     onSelect={() => handleActionSelect(action)}
  //                   >
  //                     {action}
  //                   </DropdownMenu.Item>
  //                 ))} */}
  //               </DropdownMenu.Content>
  //             </DropdownMenu.Portal>
  //             <div>
  //               {data.title ? <div className="title">{data.title}</div> : null}
  //               {data.subline ? (
  //                 <div className="subline">{data.subline}</div>
  //               ) : null}

  //               {/* <div className="flex row space-x-8">
  //                 <NodeForm
  //                   isLateral={isLateral}
  //                   schema={inputSchema}
  //                   onChange={onInputsChange}
  //                 />
  //                 <NodeForm
  //                   isLateral={isLateral}
  //                   schema={outputSchema}
  //                   onChange={onOutputsChange}
  //                 />
  //               </div> */}
  //             </div>
  //           </div>
  //           <Handle
  //             type="target"
  //             position={isLateral ? Position.Left : Position.Top}
  //           />
  //           <Handle
  //             type="source"
  //             position={isLateral ? Position.Right : Position.Bottom}
  //           />
  //         </div>
  //       </DropdownMenu.Root>
  //     </div>
  //   </>
  // );
}
export default TurboNode;
export const nodeTypes = {
  turbo: TurboNode
};