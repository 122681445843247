import upsertJsonFile from './json/upsertJsonFile';
import checkFolderExists from './checkFolderExists';
import { PATH_USERS, pathJoin } from '../filepaths';

export default async function updateSecrets(
  address: string,
  secrets: Record<string, any>,
): Promise<any> {
  const userHome = pathJoin(PATH_USERS, address);
  const secretsKey = pathJoin(userHome, 'secrets.json');

  try {
    // Check if the account folder exists
    const folderExists = await checkFolderExists(secretsKey);
    if (!folderExists) {
      console.warn(`Account folder not found: ${secretsKey}`);
      return null;
    }
    // If the folder exists, upsert the secrets
    await upsertJsonFile({
      s3ObjectKey: secretsKey,
      updatedJson: secrets,
    });
    return true;
  } catch (err: any) {
    console.error(`Error retrieving secrets file: ${err}`);
    return null;
  }
}

/*
SSS
W
ORM
*/
