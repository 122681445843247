'use client';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactFlow, { useNodesState, useEdgesState, Node, Edge, addEdge, Connection, updateEdge, useStore, useStoreApi, Background, BackgroundVariant } from 'reactflow';
import Image from 'next/image';
import { fitViewOptions, proOptions } from '#/config/constants';
import { useFlowContext } from '#/providers/FlowContext';
import { IUser, User } from '#/services/user';
import { getLatestWorkflowRun } from '#/services/workflows/runs/getLatestWorkflowRun';
import { IWorkspace, RunStatus } from '#/types/workspace';
import { edgeTypes } from './Nodes/TurboEdge';
import { nodeTypes } from './Nodes/TurboNode';
import dynamic from 'next/dynamic';
const ConfusedTravolta = dynamic(() => import('./ConfusedTravolta'), {
  ssr: false
});
const FlowInterfaces = dynamic(() => import('./FlowInterfaces'), {
  ssr: false
});
const defaultEdgeOptions = {
  type: 'turbo',
  markerEnd: 'edge-circle',
  animated: true
};
function mapNodesToStatus(nodes: Node[], workflowRunOutput: any) {
  return nodes.map(node => {
    const nodeTaskRun = workflowRunOutput[node.id];
    const nodeOutput = nodeTaskRun?.output;
    const nodeStatus = nodeTaskRun?.status;
    return {
      ...node,
      selected: nodeStatus === 'RUNNING',
      data: {
        ...node.data,
        status: nodeStatus,
        output: nodeOutput
      }
    };
  });
}
function WorkspaceFlow(props: {
  user?: User;
  workspaceUuid: string;
  isLateral?: boolean;
  fetchWorkflowRunData?: () => Promise<void>;
  isSaving: boolean;
  setIsSaving: (isSaving: boolean) => void;
  saveWorkspace?: (workspace: Partial<IWorkspace>) => Promise<void>;
}) {
  const {
    user,
    workspaceUuid,
    isLateral = false,
    fetchWorkflowRunData,
    isSaving,
    setIsSaving,
    saveWorkspace
  } = props ?? {};

  // const state = useStore();
  // const { getState } = useStoreApi();
  // console.debug({ state: store.getState() });

  const {
    isTestRunning,
    canDeleteNode,
    isNodeDetailsShown,
    toggleIsSettingsShown,
    nodes,
    setNodes,
    onNodesChange,
    edges,
    setEdges,
    onEdgesChange,
    saveState
  } = useFlowContext();
  const edgeUpdateSuccessful = useRef(true);
  useEffect(() => {
    (async () => {
      console.debug('[WorkspaceFlow] debug:', {
        nodes,
        edges
      });
      // TODO: statically validate the new workspace
      // if (workspace.owner == null) {
      //   console.error(
      //     '[WorkspaceFlow] workspace.owner is required to update workspace',
      //   );
      //   return;
      // }
      // await updateWorkspace({
      //   ownerAddress: workspace.owner,
      //   workspaceUuid,
      //   updatedWorkspace: workspace,
      // });
    })();
  }, [nodes, edges]);

  // useEffect(() => {
  //   // Placeholder for establishing a WebSocket/SSE connection
  //   const subscribeToStatusUpdates = async () => {
  //     // const ws = new WebSocket('YOUR_BACKEND_WS_URL');
  //     // ws.onmessage = (event) => {
  //     //   const { nodeId, status } = JSON.parse(event.data);
  //     //   setNodes((prevNodes) =>
  //     //     prevNodes.map((node) => {
  //     //       if (node.id === nodeId) {
  //     //         return { ...node, data: { ...node.data, status } };
  //     //       }
  //     //       return node;
  //     //     }),
  //     //   );
  //     // };
  //     // return () => ws.close();
  //   };

  //   subscribeToStatusUpdates();
  // }, []);

  const onConnect = useCallback((params: Edge<any> | Connection) => setEdges(els => addEdge(params, els)), [setEdges]);
  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false;
  }, []);
  const onEdgeUpdate = useCallback((oldEdge: Edge<any>, newConnection: Connection) => {
    edgeUpdateSuccessful.current = true;
    setEdges(els => updateEdge(oldEdge, newConnection, els));
  }, [setEdges]);
  const onEdgeUpdateEnd = useCallback((_: any, edge: {
    id: string;
  }) => {
    if (!edgeUpdateSuccessful.current) {
      setEdges(eds => eds.filter(e => e.id !== edge.id));
    }
    edgeUpdateSuccessful.current = true;
  }, [setEdges]);
  const [status, setStatus] = useState<RunStatus | null>(null);
  // const [statusMessage, setStatusMessage] = useState<string>('');

  useEffect(() => {
    async function getWorkflowStatus() {
      try {
        if (!user?.getAddress() || !workspaceUuid) return;

        // const response = await fetch('/api/getCurrentWorkflowStatus');
        // const responseJson = await response.json();
        // // toast.info(`Workflow Status: ${responseJson.status}`);

        const currentWorkflowRun = await getLatestWorkflowRun({
          ownerAddress: user?.getAddress(),
          workflowUuid: workspaceUuid
        });
        const status = currentWorkflowRun?.status;
        console.debug({
          status
        });
        setStatus(status);
      } catch (error) {
        // toast.error(`Workflow Status: Error Occured`);
        setStatus('ERROR');
        // setStatusMessage(`error getting workflow status: ${error.message}`);
        console.error('error occured when trying to get workflow status.');
      }
    }
    getWorkflowStatus();
  }, [user?.getAddress(), workspaceUuid]);

  // const [isWorkflowTaskRunDataLoading, setIsWorkflowTaskRunDataLoading] =
  //   useState(true);
  // const [workflowTaskRunData, setWorkflowTaskRunData] = useState(null);
  async function fetchWorkflowTaskRunData() {
    try {
      if (!user?.getAddress() || !workspaceUuid) return;

      // setIsWorkflowTaskRunDataLoading(true);

      const workflowRun = await getLatestWorkflowRun({
        ownerAddress: user?.getAddress(),
        workflowUuid: workspaceUuid
      });
      console.debug({
        workflowRun
      });
      const workflowRunOutput = workflowRun?.output ?? {};
      // setWorkflowTaskRunData(workflowRunOutput);
      setNodes(prevNodes => mapNodesToStatus(prevNodes, workflowRunOutput));
    } catch (error) {
      console.error('Error fetching workflow task run data: ', error);
    } finally {
      // setIsWorkflowTaskRunDataLoading(false);
    }
  }
  useEffect(() => {
    fetchWorkflowTaskRunData();
  }, [workspaceUuid, isNodeDetailsShown]);
  useEffect(() => {
    if (!isTestRunning) return;
    const interval = 5000;
    const handle = setInterval(() => {
      fetchWorkflowTaskRunData();
    }, interval);
    return () => {
      clearInterval(handle);
    };
  }, [workspaceUuid, isTestRunning]);
  const handleNodesChange = changes => {
    onNodesChange(changes);
    saveState();
  };
  const handleEdgesChange = changes => {
    onEdgesChange(changes);
    saveState();
  };
  return <>
      <ReactFlow nodes={nodes} edges={edges} onNodesChange={handleNodesChange} onEdgesChange={handleEdgesChange} onConnect={onConnect} onEdgeUpdate={onEdgeUpdate} onEdgeUpdateStart={onEdgeUpdateStart} onEdgeUpdateEnd={onEdgeUpdateEnd} fitView fitViewOptions={fitViewOptions} minZoom={0.1} maxZoom={8} nodeTypes={nodeTypes} edgeTypes={edgeTypes} proOptions={proOptions} defaultEdgeOptions={defaultEdgeOptions} deleteKeyCode={canDeleteNode ? ['Backspace', 'Delete'] : null}
    // onInit={onInit}
    data-sentry-element="ReactFlow" data-sentry-source-file="Flow.tsx">
        {/* <Background color="#ccc" variant={BackgroundVariant.Dots} /> */}
        <FlowInterfaces workspaceUuid={workspaceUuid} isLateral={isLateral} status={status} fetchWorkflowRunData={fetchWorkflowRunData} isSaving={isSaving} setIsSaving={setIsSaving} saveWorkspace={saveWorkspace} data-sentry-element="FlowInterfaces" data-sentry-source-file="Flow.tsx" />
        <ConfusedTravolta data-sentry-element="ConfusedTravolta" data-sentry-source-file="Flow.tsx" />
      </ReactFlow>
    </>;
}
export default WorkspaceFlow;