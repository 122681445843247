import { FitViewOptions } from 'reactflow';

import pkg from '@/package.json';
import versionInfo from '@/version.json';

export const APP_VERSION = pkg.version;
export const APP_BUILD_TIMESTAMP = versionInfo.buildTimestamp;
export const APP_GIT_HASH = versionInfo.gitHash;
export const APP_BRANCH = versionInfo.branch;

export const APP_VERSION_DETAILS = {
  version: APP_VERSION,
  buildTimestamp: APP_BUILD_TIMESTAMP,
  gitHash: APP_GIT_HASH,
  branch: APP_BRANCH,
};

export const APP_ENV = process.env.APP_ENV || process.env.NODE_ENV;

// Storj-related constants

// const defaults = {
//   STORJ_ACCESS_KEY: 'jxzx75f4qyanrut2wdbgovghk2bq',
//   STORJ_SECRET_KEY: 'j2tc7lmlgfp7idbztnzqrdli6mrskivy3o47c3wsj6z57uwdscrso',
//   STORJ_BUCKET: 'simplifi',
//   STORJ_ENDPOINT: 'https://gateway.storjshare.io',
// };

// Alpha
const defaults = {
  STORJ_ACCESS_KEY: 'jvfqoei3d2nxwbgn244x3nhyxbda',
  STORJ_SECRET_KEY: 'jzqubjmxteeoxemeduitqe6mtf6e3dvowh7qjc6mgtfjs3g2tm2ai',
  STORJ_BUCKET: 'simplifi-dev',
  STORJ_ENDPOINT: 'https://gateway.storjshare.io',
};

// Alpha 2
// const defaults = {
//   STORJ_ACCESS_KEY: 'jwug2ok5tv2mm6rajd557tl554tq',
//   STORJ_SECRET_KEY:
//     'jyr7bbhiqz5rqiuytqr5pd3yhpyfftgczzu36rhltv7xpjrziw2mu',
//   STORJ_BUCKET: 'simplifi-dev',
//   STORJ_ENDPOINT: 'https://gateway.storjshare.io',
// };

export const STORJ_ACCESS_KEY =
  process.env.NEXT_STORJ_ACCESS_KEY || defaults.STORJ_ACCESS_KEY;
export const STORJ_SECRET_KEY =
  process.env.NEXT_STORJ_SECRET_KEY || defaults.STORJ_SECRET_KEY;
export const STORJ_BUCKET =
  process.env.NEXT_STORJ_BUCKET || defaults.STORJ_BUCKET;
export const STORJ_ENDPOINT =
  process.env.NEXT_STORJ_ENDPOINT ||
  defaults.STORJ_ENDPOINT ||
  'https://gateway.storjshare.io';

// S3-related constants (using Storj via S3)
export const AWS_S3_ACCESS_KEY = STORJ_ACCESS_KEY;
export const AWS_S3_SECRET_KEY = STORJ_SECRET_KEY;
export const AWS_S3_ENDPOINT = STORJ_ENDPOINT;
export const AWS_S3_BUCKET = STORJ_BUCKET;
export const AWS_S3_REGION = 'us-east-1';

export const minimapStyles = {
  height: 120,
  background: '#000',
};
export const minimapLabel = 'Minimap';

export const proOptions = { hideAttribution: true };

export const fitViewOptions: FitViewOptions = {
  padding: 0.45,
};
